import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import img1 from "../assets/roadmap.jpg";

const Container = styled.div`
  width: 100vw;
  min-height: 200vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};

  @media (max-width: 30em) {
    min-height: 150vh;
  }

`;
const Box = styled.div`
  width: 100vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  background-color: ${(props) => props.theme.body};

    @media (max-width: 30em) {
      min-height: 70vh;

}
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-align: center;
  font-weight: 300;
  margin: 2rem 0;
  text-transform: uppercase;

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxl};
}

`;
const Text = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  /* text-align: center; */
  font-weight: 300;
  width: 60%;
  margin: 0 auto;


  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 48em){
    width: 80%;

}
@media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
}
  /* position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%) !important; */
`;

const ImgContainer = styled.div`
  width: 100vw;
  height: auto;

  img {
    width: 100%;
    height: auto;
    object-position: bottom;
    object-fit: cover;
    position: fixed;
    top: 0;
  }

  @media (max-width: 30em) {
    img{
      height: 150vh;
      object-position: bottom;
    }
}
`;

const RoadMap = () => {

  
  const [mobile, setmobile] = useState(true);

  useLayoutEffect(() => {
    if (window.matchMedia("(min-width:30em)").matches) {
      setmobile(false);
    }
   
  }, []);

  return (
    <Container data-scroll-section >
      <ImgContainer>
      
        {
          mobile ?

          <img
          src={img1}
          alt="Unknown Man"
          
        />
          :
          <img
          src={img1}
          alt="Unknown Man"
          data-scroll
          data-scroll-sticky
          data-scroll-target="#fixed-target"
        />
        }
      </ImgContainer>
      <Box id="fixed-target" >
        <Title>Roadmap</Title>
        <Text>
        Welcome to Phase 1 of our NFT project - it's all about laying the groundwork for an epic journey ahead. 
        We're talking about building foundational knowledge and forging a strong community.
        </Text>
        
          
          <br />
          &nbsp;&nbsp;<Text></Text> - Welcome to Phase 1 of our NFT project - we're building the foundation for something truly special. 
          We're talking about Web3, community, and some seriously cool art. But we're not just about technology - we're all about people. 
          That's why we're building a community that's inclusive, supportive, and full of good vibes.<Text></Text><br />
         
          &nbsp;&nbsp;<Text></Text> - Forging the core of our Network by and building familiarity between our members. 
          By developing and creating the community culture (Principles, Values and Standards).<Text></Text><br />
          
          &nbsp;&nbsp;<Text></Text> - And let's not forget about the art. By using Twitter (for now), we're giving smaller artists a chance to showcase their talents and reach new audiences. So if you're looking for some seriously cool art, you've come to the right place.
          Join us on this wild adventure and let's create something that will make history - or at least make you smile.<br /> <br />
          
          <Text>What are you waiting for? Buy our NFT and become part of an amazing community! We promise it'll be a wild ride full of laughs and good vibes. 
          Don't miss out on this opportunity to be a part of something special. See you on the other side, where the Boat of the UnknownMen sailing to.<Text/>
     
        <br/>
        </Text>
      </Box>
    </Container>
  );
};

export default RoadMap;
