import React from "react";
import styled from "styled-components";
import img1 from "../assets/boat-yellow.jpg";

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
height: auto;
  overflow: hidden;
  z-index: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};

  @media (max-width: 64em) {
 padding-bottom: 5rem;

}
`;

const Box = styled.div`
  width: 80%;
  height: 80vh;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 64em) {
  flex-direction: column-reverse;
  min-height: 100vh;
  height: auto;

}
`;

const Left = styled.div`
  width: 40%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 64em) {
    width: 100%;

}
`;

const Right = styled.div`
  height: 100%;
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 64em) {
    width: 100%;

}
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  text-align: center;
  font-weight: 300;
  margin-bottom: 2rem;
  text-transform: uppercase;
  align-self: flex-start;

`;




const Text = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  text-align: left;
  font-weight: 300;

  
@media (max-width: 70em) {
  font-size: ${(props) => props.theme.fontsm};

}
`;

const Start = () => {




  return (
    <Container data-scroll-section id="Start">
      <Box>
        <Right data-scroll data-scroll-speed="-1" >
        
          <Title >
        Welcome.
          </Title>
          <Text>
          Welcome to our community! We're all about having fun and making an impact. While we may not have all the answers, we're committed to sharing our knowledge and experiences with you in a relaxed and approachable way. 
          Think of us as your chill financial advisors. Together, we'll navigate the world of finance and achieve our goals with a smile on our faces.
          We believe that learning and growing should be enjoyable, and that's why we've created a community that's both informative and fun. Our team is made up of people just like you - passionate, curious, and always eager to learn. 
          We may not have all the answers, but we're dedicated to doing our best and providing you with valuable insights and advice.
          
          So come join us and let's make a positive impact together. Whether you're just starting out or you're a seasoned pro, there's always more to learn and discover. 
          Let's explore the exciting world of finance and build a brighter future, one step at a time.
          </Text>
          <br />

          <br />

          <Title >Our why?</Title>

          <Text>
         At UnknownMen NFT, we're on a mission to change the world - one digital art piece at a time. 
         We believe that art has the power to inspire, unite, and create real impact in the world. 
         And with the emergence of NFTs, we see an incredible opportunity to bring this vision to life.
         Our NFT project is all about community, creativity, and innovation. We're building a space where artists and creators can showcase their talents, connect with like-minded individuals, and make a real impact in the world of digital art.
         But we're not just about the art - we're also about the people. We believe in creating a community that's inclusive, supportive, and full of good vibes.
         
         A place where everyone can be themselves, express their creativity, and collaborate with others to create something truly special.
         So if you're looking to be a part of something that's more than just a collectible, but a movement, join us at UnknownMen. 
         
         Together, we can create a better world through the power of art and community. 
         So buckle up, buttercup, and get ready for a journey that's equal parts silly and exciting. Let's make some NFT magic!
          
           <Text>
          Our financial advice may not be as exciting as a roller coaster ride, but we'll try to add a little humor to make it a smoother journey.</Text>
           <br />
          </Text>
        </Right>
        <Left data-scroll data-scroll-direction="horizontal">
          <img
            data-scroll
            data-scroll-speed="2"
            src={img1}
            alt="Unknown Men NFT"
          />
        </Left>
      </Box>
    </Container>
  );
};

export default Start;
