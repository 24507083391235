/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import img1 from "../assets/boat-purple.jpg";

const Container = styled.div`
  width: 80vw;
  min-height: 100vh;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 5rem;
  position: relative;

  background-color: ${(props) => `rgba(${props.theme.textRgba},0.8)`};
  color: ${(props) => props.theme.text};

  background-image: url(${img1});
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (max-width: 48em) {
    width: 100vw;
    padding: 0;
    min-height: 150vh;


  }

  @media (max-width: 25em) {
    min-height: 200vh;

  }

`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-align: center;
  font-weight: 300;
  margin-bottom: 2rem;
  text-transform: uppercase;
  width: 100%;
`;

const SubTitle = styled.h1`
  font-size: ${(props) => props.theme.fontlg};
  text-align: left;
  font-weight: 300;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 25em) {
    font-size: ${(props) => props.theme.fontmd};

    width: 90%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => `rgba(${props.theme.bodyRgba},0.7)`};
  z-index: 1;
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;

  @media (max-width: 25em) {
    min-height: 100vh;
    height: 100%;

  }
`;

const Text = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  text-align: left;
  font-weight: 300;

  width: 80%;
  margin: 0 auto;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontsm};
  }

  li {
    margin-left: 2rem;
  }

  @media (max-width: 25em) {
    font-size: ${(props) => props.theme.fontsm};
    padding: 0.5rem 0;
    width: 90%;
  }
`;

const Vision = () => {

  return (
    <Container data-scroll-section id="vision">
      <Overlay />
      <Box
        data-scroll
        data-scroll-speed={-1}
        data-scroll-direction={"verticle"}
      >
        <Title>Implementation</Title>

        <SubTitle>Community Goals</SubTitle>
        <br />
        <Text>
        Get ready to feast your eyes on the coolest digital art around! Our NFT project is all about unlocking the potential of digital art and giving artists a chance to shine.
        We know that the NFT space can be a bit overwhelming, with all the greedy sharks and flashy hype.
        But fear not - we're here to create a community that's focused on quality and collaboration, not just profit.
        And let's be real - the art is just too darn cool to ignore. With a new level of diversity and creativity, digital art is taking the world by storm.
        And we want to be at the forefront of this exciting movement. Sure, we're not building the platform just yet, but that's all the more reason to get in on the ground floor. 
        
         <br />
         <Text>As a member, you'll have access to valuable resources and information that can help you achieve your own goals and improve your daily life.</Text><br/>
          
        Who knows where this journey will take us? One thing's for sure - it's gonna be a wild ride.
        So if you're looking for something fresh and exciting in the world of NFTs, look no further. 
        </Text>
        <br />
        <Text>
      
      
       <br /><Text>But our Platform will change that.</Text><br/>

         It's a long process but the reward and potential is unimaginable.

        
            <Text>Informations about our Project:</Text>
            
            <li>Our Collection has a total of 500 NFTs</li>
            
            <li>
            The Future is in your Hands. Welcome to the Odessey of the Unknown Men.
            </li>

        </Text>
      </Box>
    </Container>
  );
};

export default Vision;
