export const dark = {

    body: '#000',
    text: '#fff',
    bodyRgba: '0,0,0',
    textRgba: '255,255,255',


    fontxs: '0.5em',
    fontsm: '0.75em',
    fontmd: '1em',
    fontlg: '1.5em',
    fontxl: '2.5em',
    fontxxl: '4em',
    fontxxxl: '6em',

    fontBig: '8em',



    navHeight:'5rem',

}